import AnchorButton from "@atoms/anchorButton"
import { Icon, RichText } from "@atoms"
import SnackBite from "@organisms/snackbite"
import moment from "moment"
import { convertFromBytes } from "@utils/mediaLibrary"
import { useMediaQuery } from "@helpers/hooks"
import { IMediaLibraryDetailsProps } from "./_mediaLibraryDetails.interface"
import { getApiDomainAndLang } from "@utils/baseApi"
import ApplicationContext from "@utils/application-context/applicationContext"
import { useContext } from "react"

const MediaLibraryDetails = (props: IMediaLibraryDetailsProps) => {
  const {
    date,
    title,
    body,
    alignment,
    fieldMediaSubHeading,
    fieldSubHeadingDescription,
    newsAndStories,
    taxonomyLabel,
    mediaLibConfig,
    fileSize,
  } = props
  const { applicationConfiguration } = useContext(ApplicationContext)
  const isMobile = useMediaQuery("(max-width: 575px)")
  const isTablet = useMediaQuery("(min-width: 576px) and (max-width: 991px)")

  let imageHeight = 432
  if (isMobile) {
    imageHeight = 185
  } else if (isTablet) {
    imageHeight = 285
  }
  const { lang } = getApiDomainAndLang()

  const paragraph = `<h6>${moment(date)
    .locale(lang)
    .format(applicationConfiguration?.siteConfig?.date_format)}</h6><h1>${title}</h1><p>${body}</p>`
  const titleNewsAndStoriesDetails = { paragraph, alignment }
  const imageDescriptionParagraph = `<h3>${fieldMediaSubHeading}</h3><p>${fieldSubHeadingDescription}</p>`
  const detailNewsAndStories = { ...newsAndStories, imageDescriptionParagraph, imageHeight }
  return (
    <div className="media-details-wrapper">
      <div className="media-details">
        <SnackBite
          titleNewsAndStoriesDetails={titleNewsAndStoriesDetails}
          detailNewsAndStories={detailNewsAndStories}
        />
      </div>

      <div className="bottom-content">
        <RichText className="snac-desc" content={imageDescriptionParagraph} />
        {!!taxonomyLabel && !!fileSize && (
          <p className="snac-desc-type">
            {`${taxonomyLabel.split(".").pop()?.toUpperCase()}, ${convertFromBytes(fileSize)}`}
          </p>
        )}
      </div>
      <div className="bottom-button">
        {taxonomyLabel && (
          <AnchorButton
            isExternal={true}
            href={taxonomyLabel || ""}
            tabindex={1}
            download={true}
            isSecondary
            icon={<Icon iconName="DownloadIcon" />}>
            {mediaLibConfig.download_text_cta}
          </AnchorButton>
        )}
      </div>
    </div>
  )
}

export default MediaLibraryDetails

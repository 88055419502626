import { Button, Column, Icon, Input } from "@atoms"
import Loader from "@atoms/loader"
import ProductsTable from "@atoms/productsTable"
import NUMBERS from "@helpers/constants/numbers"
import { getApi } from "@utils/baseApi"
import debounce from "lodash.debounce"
import { useCallback, useEffect, useState } from "react"
import { ISuggestionBar } from "./_suggestionBar.interface"

const SuggestionBar = (props: ISuggestionBar) => {
  const {
    searchLabel,
    placeholder,
    buttonLabel,
    fieldsData,
    handleSearchText,
    fieldName,
    productFoundText = "",
    handleSearchAction,
    productTableData,
    handleProductTableChange,
    isClear,
    isLoading,
  } = props

  const [textInput, setTextInput] = useState("")
  const [displaySuggestions, setDisplaySuggestions] = useState(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [searchCTAEnabled, enableSearchCTA] = useState<boolean>(false)
  const [suggestionList, setSuggestionList] = useState<{ id: string; name: string }[]>([])
  const [familyID, setFamilyID] = useState<string>("")
  const [noResultsText, setNoResultsText] = useState<string>("")
  const [tableData, setTableData] = useState<any>([])

  const MIN_SEARCH_TEXT_LENGTH = NUMBERS.TWO
  const nameOfField = fieldName?.[0] as string
  const { soi_2_channel: channel, soi_2_country: country, smart_mi_country: smartCountry, smart_med_enq_channel: smartChannel } = fieldsData
  const fieldKey = fieldName?.[0] ?? ""

  const getSearchSuggestions = async (value: string, channel: string, country: string, smartCountry: string, smartChannel: string) => {
    if (value?.length > MIN_SEARCH_TEXT_LENGTH) {
      const productUrl =
        fieldKey !== "smart_med_enq_product"
          ? `/restapi/pim/sendout-autosuggest?text_search=${value}&channel=${channel}&country=${country}`
          : `/restapi/pim/smenq-prod-list?country_code=${smartCountry}&prod_name=${value}&channel=${smartChannel}`
      const searchSuggestions = await getApi(productUrl)
      const searchSuggestionsRes =
        fieldKey !== "smart_med_enq_product"
          ? searchSuggestions
          : Object.entries(searchSuggestions?.product_data ?? {}).map(([id, name]) => ({
              id,
              name,
            }))
      setSuggestionList(searchSuggestionsRes)
      setNoResultsText(searchSuggestions?.message)
    } else {
      setSuggestionList([])
    }
    setLoading(false)
  }
  const debounceSearch = useCallback(
    debounce(
      (searchText: string, channel: string, country: string, smartCountry: string, smartChannel: string) =>
        getSearchSuggestions(searchText, channel, country, smartCountry, smartChannel),
      500,
    ),
    [],
  )

  useEffect(() => {
    setTextInput("")
  }, [isClear, channel?.[0], country?.[0], smartCountry?.[0], smartChannel?.[0]])

  useEffect(() => {
    setTableData(productTableData)
  }, [productTableData])

  useEffect(() => {
    if (textInput && displaySuggestions) {
      setLoading(true)
      debounceSearch(textInput, channel?.[0] ?? "", country?.[0] ?? "", smartCountry?.[0] ?? "", smartChannel?.[0] ?? "")
      setTableData([])
    }
    if(textInput?.length < NUMBERS.THREE){
      enableSearchCTA(false)
    }
  }, [textInput])

  const handleFocus = () => {
    setDisplaySuggestions(true)
  }

  const closeList = () => {
    setDisplaySuggestions(false)
  }

  const formatText = (text: { id: string; name: string }) => {
    if (textInput !== "" && text?.name) {
      return <span id={text?.id}>{text?.name}</span>
    }
    return <></>
  }

  const handleClick = (item: { id: string; name: string }) => {
    setFamilyID(item?.id)
    setDisplaySuggestions(false)
    enableSearchCTA(true)
    setTextInput(item?.name)
    handleSearchText(item?.name, item?.id, nameOfField, productFoundText)
  }

  const handleInputBlur = () => {
    if(textInput.length > MIN_SEARCH_TEXT_LENGTH) {
      handleSearchText(textInput, "", nameOfField, productFoundText)
    }
    setTimeout(() => {
      setDisplaySuggestions(false)
    }, 200)
  }

  return (
    <>
      <div className="suggestion-bar">
        <Column desktop={12} tablet={12} mobile={12} className={`suggestion-bar-group ${fieldKey === "smart_med_enq_product" ? "no-right-padding" : ""}`}>
          <Column desktop={fieldKey !== "smart_med_enq_product" ? 10 : 12} tablet={12} mobile={12} className="content-bar">
            <label className="label">{searchLabel}</label>
            <Input
              className="input-align search-box"
              value={textInput}
              useStateValue={false}
              type="search"
              placeholder={placeholder}
              setTextInput={setTextInput}
              onFocus={handleFocus}
              onChange={handleFocus}
              onBlur={handleInputBlur}
            />
            {displaySuggestions && textInput?.length > MIN_SEARCH_TEXT_LENGTH ? (
              <>
                <div className="suggestion-list" id="suggestion-list">
                  <ul>
                    {loading && (
                      <li>
                        <Loader display={loading} />
                      </li>
                    )}
                    {!loading &&
                      (suggestionList?.length > 0 ? (
                        <>
                          {suggestionList.map((item, key) => (
                            <>
                              {item?.id && item?.name && (
                                <li key={`${key + item?.id}`}>
                                  <button className="button" onClick={() => handleClick(item)}>
                                    {formatText(item)}
                                  </button>
                                </li>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <li>{noResultsText}</li>
                      ))}
                  </ul>
                </div>
                <div className="blanket" onClick={closeList} />
              </>
            ) : (
              <></>
            )}
          </Column>
          {fieldKey !== "smart_med_enq_product" ? (
            <Column desktop={2} tablet={2} mobile={12} className="search-group">
              <Button
                className="search-button"
                tabindex={0}
                type="button"
                isDisabled={!searchCTAEnabled}
                onClick={() => handleSearchAction(familyID, country?.[0], channel?.[0])}
                icon={<Icon iconName="SearchIcon2" />}
                iconPosition="right">
                {buttonLabel}
              </Button>
            </Column>
          ) : (
            <></>
          )}
        </Column>
      </div>
      {searchCTAEnabled && tableData && tableData?.product_data?.length > 0 && channel?.[0] && country?.[0] ? (
        <div className={productTableData ? "product-bar" : ""}>
          <ProductsTable
            product_data={productTableData?.product_data}
            total_product_count={productTableData?.total_product_count}
            asset_attributes_list={productTableData?.asset_attributes_list}
            handleProductTableChange={handleProductTableChange}
            productFamilyName={textInput}
            attributeData={tableData?.asset_attributes_list}
          />
        </div>
      ) : (
        <>
          {isLoading && (
            <div className="load-container">
              <Loader display={true} />
            </div>
          )}
        </>
      )}
    </>
  )
}

export default SuggestionBar

import { Column, Icon, Img, RichText, Row, Taxonomy, Anchor } from "@atoms"
import { IMagazineVerticalCardProps } from "./_magazineVerticalCard.interface"
import { trimText } from "@utils/stringparsing"
import CHARLIMIT from "@helpers/constants/charLimit"
import moment from "moment"
import ApplicationContext from "@utils/application-context/applicationContext"
import { useContext } from "react"
import { getApiDomainAndLang } from "@utils/baseApi"

const MagazineVerticalCard = (props: IMagazineVerticalCardProps) => {
  const { title, magImage, row, desc, createdDate, taxonomies, time, nodeUrl } =
    props
    const { applicationConfiguration } = useContext(ApplicationContext)
    const { lang } = getApiDomainAndLang()
  return (
    <>
      <Column
        desktop={row}
        tablet={props.row}
        mobile={props.row ?? 12}
        className="magazine-card-vertical">
        <div className="card">
          <div className="image-section">
            {magImage?.src && (
            nodeUrl ?  <Anchor
              href={nodeUrl}
              tabindex={0}
              noUnderline={true}>
              <Img
                className="magazine-card-vertical-image"
                type="card"
                styledimage={magImage?.styledimage}
                src={magImage?.src}
                alt={magImage?.alt}
                width={368}
                height={220}
              />
            </Anchor>
            :
            <Img
              className="magazine-card-vertical-image"
              type="card"
              styledimage={magImage?.styledimage}
              src={magImage?.src}
              alt={magImage?.alt}
              width={368}
              height={220}
            />
            )}
          </div>
          <div className="card-body">
            <div className="magazine-card-vertical-content">
              <Row>
                <Column desktop={9} tablet={9} mobile={9}>
                  {taxonomies ? <Taxonomy taxonomies={taxonomies} /> : <></>}
                </Column>
              </Row>
              {title && (
                 nodeUrl ?  <Anchor
                 href={nodeUrl}
                 tabindex={0}
                 noUnderline={true}>
                  <h4 className="magazine-card-vertical-content-header bold">
                    {trimText(title, CHARLIMIT.TITLE)}
                  </h4>
                </Anchor>
                :
                <h4 className="magazine-card-vertical-content-header bold">
                  {trimText(title, CHARLIMIT.TITLE)}
                </h4>
              )}
              {desc && (
                <RichText
                  className="magazine-card-vertical-content-text magazine-card-vertical-content-text-h"
                  content={desc}
                  characterLimit={CHARLIMIT.DESCRIPTION}
                />
              )}
              <div className="magazine-card-vertical-content-bottom">
                <span className="magazine-card-vertical-content-bottom-icon">
                  <Icon iconName="calenderIcon" width={24} height={24} />
                </span>
                {createdDate && (
                  <div className="magazine-card-vertical-content-event-date">
                    {moment(createdDate)
                      .locale(lang)
                      .format(applicationConfiguration?.siteConfig?.date_format)}
                  </div>
                )}
              </div>
              <div className="magazine-card-vertical-content-time-section">
                <Icon iconName="TimeIcon" />

                {time && (
                  <div className="magazine-card-vertical-content-time-section-time">{time}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Column>
    </>
  )
}

export default MagazineVerticalCard
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, Icon, Seperator } from "@atoms"
import { useMediaQuery } from "@helpers/hooks"
import SearchFilterTable from "@organisms/searchFilterTable"
import { Pagination, TitleDescription } from "@molecules"
import { useRouter } from "next/router"
import { IOption } from "@atoms/dropDown/_dropDown.interface"
import {middlewareGETAPI } from "@utils/baseApi"
import { 
  fetchCoListingTableData, 
  getCoFilterLabelData 
} from "@helpers/dataFunctions/getCOListSearchData"
import { searchFilterType } from "@organisms/searchFilterTable/_searchFilterTable.interface"
import Loader from "@atoms/loader"
import NUMBERS from "@helpers/constants/numbers"
import EnhancedFilterSearch from "@organisms/enhancedFilterSearch"
import { connect, useDispatch } from "react-redux"
import { Dispatch } from "redux"
import _ from "lodash"
import { ColumnNameType } from "./_company-search-filter.interface"
import ApplicationContext from "@utils/application-context/applicationContext"
import {
  addUpdateCOListFilter,
  updateAllCOListFilters,
  updateCOListPagination,
  updateCOListSearchText,
  updateCOListSort,
} from "store/actions/COListSearchActionCreators"
import exportFromJSON from "export-from-json"

const ITEMS_PER_PAGE = NUMBERS.TEN
interface DispatchProps {
    addUpdateCOListFilter: (data: any) => void
    updateCOListPagination: (data: number) => void
    updateCOListSearchText: (data: string) => void
    updateAllCOListFilters: (data: any, page: number, str: string, sort?: any) => void
    updateCOListSort: (data: any) => void
  }
  
  type StateTypes = {
    coListSearchFilterData: {
      coListFilters:any
      currentPage: number
      searchText: string
      sort: {
        column: string
        order: string
      }
    }
  }

  type COListingProps = {
    blockUrl: string
  }
  
  
  const sortKeyPair: ColumnNameType = {
      soldToNumber: "sold_to_number",
      companyName: "company_name",
   }
type Props = DispatchProps & StateTypes & COListingProps
const CompanySearchFilter = (props: Props) => {
  const { coListSearchFilterData, blockUrl } = props
  const { coListFilters, currentPage, searchText, sort } = coListSearchFilterData
  const [currentSearchText, setCurrentSearchText] = useState("")
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [sortValue, setSortValue] = useState<any>({ value: "", order: 0 })
  const [coList, setCOList] = useState<searchFilterType[]>([])
  const [sortedList, setSortedList] = useState<searchFilterType[]>([])
  const [filterDetails, setFilterDetails] = useState<any>({})
  const [resultCount, setResultCount] = useState<number>(0)
  const [isUpdated, setIsUpdated] = useState(false)
  const [localSelectedFilters, setLocalSelectedFilters] = useState<any>(coListFilters)
  const [itemsPerPage, setItemsPerPage] = useState<number>(ITEMS_PER_PAGE)

  const isMobile = useMediaQuery("(max-width: 991px)")
  const router = useRouter()
  const disabledColumnSort = ["createdBy", "actionItem"]
  const filterList: searchFilterType[] = sortValue ? sortedList : coList
  // const { lang } = getApiDomainAndLang()

  const { applicationConfiguration } = useContext(ApplicationContext)

  
  const dispatch: Dispatch<any> = useDispatch()

  const updateCOListFilterState: any = useCallback(
    (value: any) => {
      props.addUpdateCOListFilter(value)
    },
    [dispatch],
  )
  const updatePaginationState: any = useCallback(
    (value: number) => props.updateCOListPagination(value),
    [dispatch],
  )

  const updateSearchTextState: any = useCallback(
    (searchText: string) => props.updateCOListSearchText(searchText),
    [dispatch],
  )
  const updateAllFiltersState: any = useCallback(
    (value: any, page: number, searchText: string, sort?: any) =>
      props.updateAllCOListFilters(value, page, searchText, sort),
    [dispatch],
  )

  const updateCOListSortState: any = useCallback(
    (value: any) => props.updateCOListSort(value),
    [dispatch],
  )

  useEffect(() => {
    if (!applicationConfiguration?.siteConfig?.company_listing) {
      return
    }

    const fetchFilterDetails = () => {
      const configData = getCoFilterLabelData(applicationConfiguration.siteConfig)
      const filterLabelsData = {
        searchLabel: configData.searchLabel,
        searchPlaceholder: configData.searchPlaceholder,
        SearchCTAText: configData.searchCTALabel,
        resultsFoundLabel: configData.resultsLabel,
        noResultsFoundLabel: configData.noResultsLabel,
        sortByCTAText: configData.sortByLabel,
        exportToCTALabel: configData.exportToCTALabel,
        addCompanyCTALabel:configData.addCompanyCTALabel
      }
      const defaultFilter = [] as any

      const createTableColumns = (oldObj: any) => {
        const objKeyMap = {
          soldToNumber: "soldToNumber",
          companyName: "companyName",
          createdBy: "createdBy",
          actionItem: "actionItem",
        }
        const newObj = {}
        Object.entries(objKeyMap).forEach(([key, value]) => {
          // @ts-ignore
          newObj[key] = oldObj[value]
        })
        return newObj
      }
      const allColumns = createTableColumns(configData.tableColumns)
      const displayConfig = {
        tableColumns: Object.values(configData.tableColumns),
        resultsLabel: configData.resultsLabel,
        noResultsLabel: configData.noResultsLabel,
        noResultsDesc: configData.noResultsDesc,
        sortByLabel: configData.sortByLabel,
        searchUrl: `${blockUrl}`,

      }

      const filterDetailsObj = {
        filtersLabels: filterLabelsData,
        jobLocationData: null,
        displayConfig,
        defaultFilter,
        tableColumns: allColumns,
      }
      const selectedCategories = router?.query
      
        const sortFilter = {
          column: selectedCategories?.sort_field || "",
          order: selectedCategories?.sort_order || "ASC",
        }
        updateAllFiltersState(
          [],
          Number(selectedCategories?.page ?? 0),
          selectedCategories?.search || "",
          sortFilter,
        )
      setFilterDetails(filterDetailsObj)
      setIsUpdated(true)
    }
    fetchFilterDetails()
  }, [applicationConfiguration?.siteConfig])

  const handleSearch = (text: string) => {
    updateSearchTextState(text)
  }

  const handleClick = (label: string, value: IOption) => {
    const alteredFilter = coListFilters?.map(
      (item: { filterName: string; selectOptions: { options: any[] } }) => {
        if (item.filterName === label) {
          return {
            ...item,
            selectOptions: {
              ...item.selectOptions,
              options: item.selectOptions.options?.map((val) => {
                if (val.id === value?.id) {
                  return {
                    ...val,
                    isSelected: false,
                  }
                }
                return val
              }),
            },
          }
        }
        return item
      },
    )
    updateCOListFilterState(alteredFilter)
  }
  const handleSortWhole = (key: string) => {
    if (sort.column === "") {
      updateCOListSortState({ column: key, order: "ASC" })
    } else if (sort.column && sort.column === key) {
      if (sort.order === "ASC") {
        updateCOListSortState({ column: key, order: "DESC" })
      } else if (sort.order === "DESC") {
        updateCOListSortState({ column: key, order: "ASC" })
      }
    } else {
      updateCOListSortState({ column: key, order: "ASC" })
    }
  }

  const handleSortKey = (key: string) => {
    if (sort.column === "" || sort.column !== key) {
      updateCOListSortState({ column: key, order: sort?.order })
    } else {
      updateCOListSortState({ column: "", order: sort?.order })
    }
  }

  const handleSortOrder = () => {
    updateCOListSortState({ column: sort?.column, order: sort.order === "ASC" ? "DESC" : "ASC" })
  }

  const checkIfValueExists = (val: any, valArr: any) =>
    valArr.findIndex((item: any) => item.id === val.id) > -1

  const handleAdditionalFilters = (label: string, value: any) => {
    if (isMobile) {
      const alteredFilter = localSelectedFilters?.map(
        (item: { filterName: string; selectOptions: { options: any[] } }) => {
          if (item.filterName === label) {
            return {
              ...item,
              selectOptions: {
                ...item.selectOptions,
                options: item.selectOptions.options?.map((val) => ({
                  ...val,
                  isSelected: checkIfValueExists(val, value),
                })),
              },
            }
          }
          return item
        },
      )
      setLocalSelectedFilters(alteredFilter)
    } else {
      const alteredFilter = coListFilters?.map(
        (item: { filterName: string; selectOptions: { options: any[] } }) => {
          if (item.filterName === label) {
            return {
              ...item,
              selectOptions: {
                ...item.selectOptions,
                options: item.selectOptions.options?.map((val) => ({
                  ...val,
                  isSelected: checkIfValueExists(val, value),
                })),
              },
            }
         } 
         return item
        },
      )
      updateCOListFilterState(alteredFilter)
    }
  }

  useMemo(() => {
    const searchUrlStr = searchText ? `&search=${encodeURIComponent(searchText)}` : ""

    const sortColumn = sort && sort?.column ? sortKeyPair[sort?.column] : ""
    const sortString = sortColumn
      ? `&sort[sort_by]=${sortColumn}&sort[sort_order]=${sort?.order}`
      : ""
    const getTableData = async () => {
      setIsLoading(true)
      const response = await middlewareGETAPI(
        `/restapi/customer-connect/company-listing?${searchUrlStr}&page=${currentPage}${sortString}`,
      )
      if (response && response.response) {
        const formatedTableData = fetchCoListingTableData(response?.response)
        setCOList(formatedTableData)
        setSortedList(formatedTableData)
        // @ts-ignore
        const totalResults = response?.response?.meta?.count || 0
        // @ts-ignore
        const itemsToRender = Number(response?.response?.number_of_items_per_page) ?? 0
        setItemsPerPage(itemsToRender)
        setResultCount(totalResults)
      }
      setIsLoading(false)
    }
    if (isUpdated) {
      getTableData().then(() => {
        const { protocol, host, pathname } = window.location
        const newurl = `${protocol}//${host}${pathname}`//&page=${currentPage}&search=${searchText || ""}${sortUrl}
        window.history.replaceState({ path: newurl }, "", newurl)
        setLocalSelectedFilters(coListFilters)
      })
    }
  }, [coListSearchFilterData , isUpdated])

  const applyAdditionalFilters = () => {
    updateCOListFilterState(localSelectedFilters)
  }

  const handlePagination = (offSet: number) => {
    updatePaginationState(offSet / itemsPerPage)
  }

  const clearAllFilters = () => {
    const clearedFilters = coListFilters?.map((item: any) => ({
      ...item,
      selectOptions: {
        ...item.selectOptions,
        options: item.selectOptions.options?.map((val: any) => {
          // @ts-ignore
          return {
            ...val,
            isSelected: false,
          }
        }),
      },
    }))
    updateAllFiltersState(clearedFilters, 0, searchText || "")
  }

  const fetLocalSelectedValue = () => {}
  
  const formatMatchString = (arrayLength: number) =>
    arrayLength > NUMBERS.ZERO
      ? filterDetails?.displayConfig?.resultsLabel?.replace(
         "@count_val",
         arrayLength.toString()
        )
      : filterDetails?.displayConfig?.noResultsLabel?.replace("@search_txt", currentSearchText)

  const resetLocalFilterWithReducer = () => {
    setLocalSelectedFilters(coListFilters)
  }
   // export to excel
   const fileName = "company list "
   const exportType = "xls"
 
   const exportToExcel = async () => {
    const coLabel = {
      sold_to_number: applicationConfiguration?.siteConfig?.company_listing?.company_listing_sold_to_no_label,
      company_name: applicationConfiguration?.siteConfig?.company_listing?.company_listing_companyname_label,
      created_by: applicationConfiguration?.siteConfig?.company_listing?.company_listing_createdby_label,
      action: applicationConfiguration?.siteConfig?.company_listing?.company_listing_action_label,
    }
     const searchUrlStr = searchText ? `&search=${encodeURIComponent(searchText)}` : ""
 
     const sortColumn = sort && sort?.column ? sortKeyPair[sort?.column] : ""
     const sortString = sortColumn
       ? `&sort[sort_by]=${sortColumn}&sort[sort_order]=${sort?.order}`
       : ""
     const response = await middlewareGETAPI(
      `${blockUrl}?${searchUrlStr}${sortString}`,
     )
     const { data }: any = response?.response
 
     if (data) {
       exportFromJSON({ data, fileName, exportType, fields: coLabel})
     }
   }
   return (
    
    <>
    {filterList?.length > 0 && (
      <div className="companylisting_buttons">
          {/* <Button
            tabindex={0}
            iconPosition="left"
            icon={<Icon iconName="add" />}
            >
            {filterDetails?.filtersLabels?.addCompanyCTALabel}
          </Button> */}
          <Button
            tabindex={0}
            onClick={exportToExcel}
            iconPosition="left"
            icon={<Icon iconName="excelToPrintIcon" />}>
            {filterDetails?.filtersLabels?.exportToCTALabel}
          </Button>
      </div>
    )}
      
      {Object.keys(filterDetails).length ? (
        <div className="careerSearchFilter scroll-top">
          <EnhancedFilterSearch
            filtersOptions={[]}
            filterLabels={filterDetails.filtersLabels}
            handleSearch={handleSearch}
            isCareerSearch={false}
            handleUnselectAll={clearAllFilters}
            handleAdditionalFilters={handleAdditionalFilters}
            selectedFilters={fetLocalSelectedValue()}
            applyAdditionalFilters={applyAdditionalFilters}
            currentSearchText={currentSearchText}
            handleRemoveItem={handleClick}
            handleSearchText={setCurrentSearchText}
            noOfResults={resultCount}
            handleSortByKey={handleSortKey}
            handleSortByOrder={handleSortOrder}
            sortList={filterDetails.tableColumns}
            selectedSortValue={sort}
            resetLocalFilterWithReducer={resetLocalFilterWithReducer}
            isDisabled={_.isEqual(coListFilters, localSelectedFilters)}
            disabledAdvanceSearch={true}
            showResultCount={true}
            displayMobileSort={true}
            disabledColumnSort={disabledColumnSort}
          />
          {!(isMobile && !coList?.length) && (
          <Seperator className="careerSearchFilter-separator" />
          )}
          {isLoading && (
            <div className="load-container">
              <Loader display={true} />
            </div>
          )}
          {!isLoading &&
            (filterList?.length ? (
              <div>
                <SearchFilterTable
                  filterData={filterList}
                  columnsList={filterDetails?.displayConfig?.tableColumns}
                  tableColumns={filterDetails?.tableColumns}
                  handleSort={handleSortWhole}
                  sortValue={sort}
                  disableSortColumnList={disabledColumnSort}
                />
                <div className="search-filter-pagination">
                  {resultCount > itemsPerPage && (
                    <div className="search-filter-pagination">
                      <Pagination
                        itemsPerPage={itemsPerPage}
                        itemsCount={resultCount}
                        onClick={handlePagination}
                        offSet={currentPage}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <TitleDescription
                title={formatMatchString(sortedList?.length) ?? ""}
                description={filterDetails.displayConfig?.noResultsDesc?.value ?? ""}
                searchKey=""
                className="careerSearchFilter-no-result"
              />
            ))}
        </div>
      ) : (
        <div className="load-container">
          <Loader display={true} />
        </div>
      )}
    </>
  )
}

const mapDispatch = {
  addUpdateCOListFilter: (data: any) => addUpdateCOListFilter(data),
  updateCOListPagination: (data: number) => updateCOListPagination(data),
  updateCOListSearchText: (data: string) => updateCOListSearchText(data),
  updateAllCOListFilters: (data: any, page: number, str: string, sort?: any) =>
    updateAllCOListFilters(data, page, str, sort),
  updateCOListSort: (data: any) => updateCOListSort(data),
}

const mapState = (state: any) => ({
  coListSearchFilterData: state.coListReducer,
})

export default connect(mapState, mapDispatch)(CompanySearchFilter)

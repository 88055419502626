import { AnchorButton, Container, Icon, Taxonomy } from "@atoms"
import { IEventBannerContentProps } from "./_eventBannerContent.interface"
import { useContext } from "react"
import { ApplicationThemeContext } from "@utils/application-theme-context/applicationThemeContext"
import moment from "moment"
import ApplicationContext from "@utils/application-context/applicationContext"
import { getApiDomainAndLang } from "@utils/baseApi"
import NUMBERS from "@helpers/constants/numbers"
import { EVENT_TYPE } from "@helpers/constants/generic"
import EventCardScoutButton from "@molecules/eventCardScoutButton"
import ButtonGroup from "@molecules/button-group"

const EventBannerContent = ({
  modalContent,
  taxonomies,
  heading,
  date,
  contentText,
  time,
  buttonLabel,
  calendarDate,
  isOverlay,
  isOverlayFontType,
  banner_cta_url,
  isExternalLink,
  scoutData,
  eventType,
}: IEventBannerContentProps) => {

  const { applicationTheme } = useContext(ApplicationThemeContext)
  const { applicationConfiguration } = useContext(ApplicationContext)
  const { lang } = getApiDomainAndLang()

  let themeClass = ""
  let themeFont = ""

  const getClasses = () => {
    if (
      applicationTheme === "rebranding" ||
      applicationTheme === "rebranding-japan" ||
      applicationTheme === "jubbonti" ||
      applicationTheme === "wyost" ||
      applicationTheme === "biosimilarsinbone" ||
      applicationTheme === "hexal" ||
      applicationTheme === "vagidonna"
    ) {
      if (isOverlay === "on" && isOverlayFontType === "light") {
        themeClass = "text-overlay-dark"
      } else if (isOverlay === "on" && isOverlayFontType === "dark") {
        themeClass = "text-overlay"
      }
      themeFont = isOverlayFontType === "light" ? "font-light" : "font-dark"
    }
    return `event-banner-content-textdata ${themeClass} ${themeFont}`
  }

  const renderShader = () => {
    let shader: any = ""
    try {
      if (applicationTheme === "hexal" && themeClass !== "") {
        shader = <div className="event-banner-content-shader" />
      }
      if (applicationTheme !== "hexal") {
        shader = <div className="event-banner-content-shader" />
      }
    } catch (err) {
      shader = ""
    }
    return shader
  }
  return (
    <div className="event-banner-content">
      <Container className="event-banner-content-align">
        <div className={getClasses()}>
          {taxonomies && <Taxonomy taxonomies={taxonomies} limitChar={NUMBERS.FIFTEEN} />}
          {heading && <h1 className="event-banner-content-header bold">{heading}</h1>}
          <div>
            <div className="event-banner-content-bottom">
              {date && (
                <div>
                  {moment(date)
                    .locale(lang)
                    .format(applicationConfiguration?.siteConfig?.date_format)}
                </div>
              )}

              {contentText && (
                <>
                  <div className="event-banner-content-line-divider" />
                  <div className="event-banner-content-map-icon-text">
                    <div className="event-banner-content-bottom-icon">
                      <Icon iconName="mapIcon" width={24} height={24} />
                    </div>
                    <div>{contentText}</div>
                  </div>
                </>
              )}

              {time && (
                <>
                  <div className="event-banner-content-line-divider" />
                  <div className="event-banner-content-time-icon-text">
                    <div>
                      <Icon iconName="TimeIcon" width={24} height={24} />
                    </div>
                    <div className="event-banner-content-time-section-time">{time}</div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            className={`${
              eventType === EVENT_TYPE.SCOUT_EVENT && scoutData
                ? "event-banner-content-button-scout"
                : ""
            }`}>
            <ButtonGroup>
              {eventType === EVENT_TYPE.SCOUT_EVENT && scoutData ? (
                <EventCardScoutButton {...scoutData} />
              ) : (
                <></>
              )}
              <>
                {banner_cta_url && (
                  <AnchorButton
                    href={banner_cta_url}
                    isExternal={isExternalLink || false}
                    className="event-banner-content-button"
                    tabindex={1}>
                    {buttonLabel}
                  </AnchorButton>
                )}
              </>
            </ButtonGroup>
          </div>
        </div>
        <div className="event-banner-content-calendar">
          {calendarDate && (
            <h2 className="event-banner-content-calendar-content">
              {moment(calendarDate).format("DD MMM").toUpperCase()}
            </h2>
          )}
        </div>
      </Container>

      {applicationTheme !== "rebranding" &&
      applicationTheme !== "rebranding-japan" &&
      applicationTheme !== "jubbonti" &&
      applicationTheme !== "wyost" &&
      applicationTheme !== "biosimilarsinbone" &&
      applicationTheme !== "vagidonna" ? (
        renderShader()
      ) : (
        <></>
      )}
    </div>
  )
}

export default EventBannerContent

import { getApi } from "@utils/baseApi"

const getCourseOverviewTeaserData = async (url: string, ...args: any) => {
  const rawData = await getApi(url.replace(/\?.*/, ""), false)

  return {
    imagePrimary: rawData?.[0]?.field_image_cpi?.[0],
    imageSecondary: rawData?.[0]?.field_secondary_info_image?.[0],
    titlePrimary: rawData?.[0]?.field_title_cpi ?? "",
    descriptionPrimary: rawData?.[0]?.field_body_cpi,
    titleSecondary: rawData?.[0]?.field_secondary_info_title ?? "",
    descriptionSecondary: rawData?.[0]?.field_secondary_info_body,
  }
}

export default getCourseOverviewTeaserData

import Icon from "@atoms/icon"
import { IModalHeaderProp } from "./_modalHeader.interface"

const ModalHeader = (props: IModalHeaderProp) => {
  const {
 titleText, isLoginTimeout, closeIcon = "", onClose = () => null, className = "", isHCP = false
} = props
  const handleClose = () => {
    onClose()
  }
  const getClasses = () => {
    const classes = ["modal-header", className].filter(Boolean)
    return classes.join(" ")
  }

  return (
    <div className={getClasses()}>
      {titleText && (
        <h4 className={`modal-title ${isHCP ? "modal-title-center" : ""}`}>
          {isLoginTimeout && <Icon iconName="InfoIcon2" />}
          {titleText}
        </h4>
      )}
      {!isLoginTimeout && !isHCP && (
        <span className="close" onClick={handleClose} onKeyDown={(e)=> {if(e.key == "Enter") {handleClose()}}} aria-label="Close">
          <Icon iconName="CloseIcon2" />
        </span>
      )}
    </div>
  )
}

export default ModalHeader

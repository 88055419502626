import React, { useContext, useEffect, useMemo, useState } from "react"
import { connect, useSelector } from "react-redux"
import { FloatingModule, FloatingModuleLegacy } from "@organisms"
import LegalBannerModule from "@organisms/legalBannerModule"
import { ILoginPopup } from "@organisms/loginPopup/_loginPopup.interface"
import { IPopup } from "@molecules/popup/_popup.interface"
import Router, { useRouter } from "next/router"
import useUserProfile from "@helpers/hooks/useUserProfile"
import { addUser, resetUser, setUserProfile } from "store/actions/ActionCreators"
import { addISIBanner } from "store/actions/ISIBannerActionCreators"

import { getAssetPrefixUrl, isAzureFormDependent } from "@utils/helper"
import dynamic from "next/dynamic"
import NUMBERS from "@helpers/constants/numbers"
import useLanguageSwitcher from "@helpers/hooks/useLanguageSwitcher"
import { getApiDomainAndLang, postApi } from "@utils/baseApi"
import isServer from "@utils/fs/isServer"
import { Notification } from "@molecules"

import { LOGIN_TYPES } from "@helpers/constants/generic"
import ApplicationContext from "@utils/application-context/applicationContext"
import { GoBackButton, Icon, ReferenceText } from "@atoms"
import { ApplicationThemeContext } from "@utils/application-theme-context/applicationThemeContext"
import RebrandingHeader from "@organisms/rebrandingHeader"
import TabChangeDetector from "./tabChangeDetector"
import {
  setRefApplicationID,
  setUserStateGlobally,
  updateReloadContent,
} from "store/actions/CommonActionCreators"
import AES from "crypto-js/aes"
import useUserSessionControl from "@helpers/hooks/userSessionControlHook/useUserSession"
import { getFlashMessage, getFlashMessageWithType, getHeaderData, renderMegaMenu } from "./_layout.data"
import { ILayoutProps, ILoginFormDataProps } from "./_layout.interface"
import { IArticleTeaserBannerProps } from "../../organisms/articleBanner/_article-banner.interface"
import { setSession } from "store/actions/SessionCreators"
import {
  fetchTokenAndSessionID,
  getApplicationPathFromURL,
  setupCookies,
} from "@utils/session-util/sessionHandler"
import { IHcpModalPopUpProps } from "@organisms/hcpModalPopUp/_hcpModalPopUp.interface"
import DYNAMIC_GOBACK_CONSTANTS from "@helpers/constants/dynamicGoBack"
import getFormattedISIData from "@helpers/dataFunctions/getISIData"
import useSandozIDAuth from "@helpers/hooks/useSandozIDAuth"
import useMicrositeConfig from "@helpers/hooks/useMicrositeConfig"
import { filterURL } from "@helpers/dataFunctions"
import _ from "lodash"
import SimplifiedHeader from "@organisms/simplifiedHeader"
import getConfigDisplayData from "@helpers/dataFunctions/getConfigDisplayData"

const StickyMenu = dynamic(() => import("../../organisms/stickyMenu"), { ssr: false })
const Header = dynamic(() => import("../../organisms/header"), { ssr: false })
const Footer = dynamic(() => import("../../organisms/footer"), { ssr: false })
const HcpModalPopUp = dynamic(() => import("../../organisms/hcpModalPopUp"), { ssr: false })
const FooterLite = dynamic(() => import("../../organisms/footerLite"), { ssr: false })

interface StateProps {
  loading: boolean
  user?: any
  profile?: any
  userSession?: any
  isiBanner?: any
  appSession?: any
  reloadContent?: boolean
}

interface DispatchProps {
  setUserProfile: (profile: any) => void
  resetUserAndProfile: (User: any) => void
  addUserData: (user: any) => void
  setUserStateGlobally: (loggedIn: boolean, loggedInType: string) => void
  setUserSession: (session: string, csrf: string) => void
  addISIBanner: (isiBannerData: any) => void
  updateRefAppID: (value: string) => void
  updateReloadContent: (isReload: boolean) => void
}

type Props = StateProps & ILayoutProps & DispatchProps

const Layout = (props: Props) => {
  const {
    loading,
    floatingBanner,
    headerMegaMenu,
    headerLightMenu,
    headerTopSection,
    floatingModule,
    loginFormData,
    eyebrowMenu,
    hcpModal,
    setUserProfile,
    resetUserAndProfile,
    addUserData,
    setUserStateGlobally,
    userSession,
    setUserSession,
    refererUrl = "",
    pageHcpModal,
    pageAlias,
    isDeepLink,
    isiBannerDetails,
    addISIBanner,
    isiBanner,
    bannerModuleData,
    updateRefAppID,
    micrositeConfig,
    countryAndBrandSwitcherData,
    updateReloadContent,
    reloadContent,
    referenceTextData,
  } = props
  const applicationConfigContext = useContext(ApplicationContext)
  const { applicationTheme } = useContext(ApplicationThemeContext)
  const { removeQueryParam } = useSandozIDAuth(applicationConfigContext?.applicationConfiguration)
  const { host, pathname } = window.location
  const loginStatus = props?.user?.isLoggedIn
  const hcpmodalpopupData = {
    content: {
      content: "",
      isRichText: false,
    },
    footer: {
      actionBtn: {
        children: "",
        tabindex: 1,
      },

      closeBtn: {
        children: "",
        href: "",
        isSecondary: false,
        tabindex: 2,
      },
    },
    header: {
      titleText: "",
    },
  }
  // get loggedin user profile data
  const router = useRouter()
  const isExcluded = isAzureFormDependent(router.asPath)
  const [stickyTopValue, setStickyTopValue] = useState(0)
  const storageHCPInt = !isServer() ? sessionStorage.getItem("hcpModal") === "interacted" : false
  const [hcpInteracted, setHCPInteracted] = useState(storageHCPInt)
  const [toastMessage, setToastMessage] = useState<string>("")
  const [showToastMessage, setShowToastMessage] = useState<boolean>(false)
  const [showFlashMessage, setShowFlashMessage] = useState<any | null>(null)
  const [popUpContent, setPopUpContent] = useState<IHcpModalPopUpProps>(hcpmodalpopupData)
  const [showHCPPopup, setShowHCPPopup] = useState<boolean>(false)
  const [showFloatingBanner, setShowFloatingBanner] = useState<string>("")
  const [l1PageNode, setL1PageNode] = useState<any>(null)
  const [l2PageNode, setL2PageNode] = useState<any>(null)
  const [pageMegaMenu, setPageMegaMenu] = useState<any>(null)
  const [loggedInUserMenu, setLoggedInUserMenu] = useState<any>(null)

  const paywalledContent: any = useSelector((state: any) => state.common.paywallContent)
  const applicationId: any = useSelector((state: any) => state.appSession.applicationId)
  const user: any = useSelector((state: any) => state.user)

  const { getCurrentPageConfig } = useMicrositeConfig(applicationConfigContext)

  // get user profile hook
  const { fetchUserProfile } = useUserProfile()
  // User session handler hook
  const {
    profileLoading,
    setProfileLoading,
    isActiveSession,
    renewUserSession,
    manageUserSession,
  } = useUserSessionControl()
  // get language hook
  const {fetchLanguageDataSource, tabOptions, tabHeadItems, tabContents} =
    useLanguageSwitcher()

  const floatingModuleISIData = getFormattedISIData(isiBannerDetails) as any
  // "0" ---> ISI 1.0, "1" ---> ISI 2.0
  const isiVersion =
    applicationConfigContext?.applicationConfiguration?.siteConfig?.isi_version ?? "0"
 
  // flag to check if site is microsite
  const isMicrositeEnabled = micrositeConfig?.isMicrositeEnabled

  // Save user session to state
  useMemo(() => {
    if (userSession && !props?.appSession?.csrf && !props?.appSession?.session) {
      const sessionData = JSON.parse(userSession)
      setUserSession(sessionData?.session, sessionData?.csrfToken)
    }
  }, [userSession])

  
  // fetch logged in user menu links
  useEffect(() => {
    const fetchLoggedInUserMenuData = async () => {
      getConfigDisplayData().then((configData: any) => {
        const headerTopSectData = configData?.data ? getHeaderData(configData.data) : headerTopSection
        setLoggedInUserMenu(headerTopSectData?.userMenuDetails)
      })  
    }
    if (user?.isLoggedIn && !loggedInUserMenu && props?.appSession?.csrf && props?.appSession?.session) {
      fetchLoggedInUserMenuData()
    }
    if (!user?.isLoggedIn) setLoggedInUserMenu(null)
  }, [user?.isLoggedIn, props?.appSession, userSession])

  // Check if paywall 3 and reload after login Sandoz id
  useMemo(async () => {
    if (
      loginStatus &&
      paywalledContent === true &&
      applicationConfigContext?.applicationConfiguration?.isSandozIDEnabled &&
      !reloadContent
    ) {
      try {
        await router.reload()
        updateReloadContent(true)
      } catch (error) {
        console.error("Error reloading page:", error)
      }
    }
  }, [
    props?.user?.isLoggedIn,
    paywalledContent,
    applicationConfigContext?.applicationConfiguration?.isSandozIDEnabled,
  ])

  /**
   * This effect will execute only when redirect from any 3rd party application - Azure, DocCheck etc
   * "code" OR "tid" can be part of 2 query params in the redirect URL
   * "code" will be there in case of DocCheck redirection
   * "tid" will be there in case solar login v2 implementation
   */
  useEffect(() => {
    const { code, app_id, tid, status_code } = router.query
    const { protocol, host, pathname } = window.location

    if (
      !applicationConfigContext?.applicationConfiguration?.flash_num_msg ||
      !applicationConfigContext?.applicationConfiguration?.error_codes ||
      (!code && !tid && !status_code)
    ) {
      return
    }

    if (status_code) {
      console.error("Error occured: Couldn't login user due to some error")
      const errorData = getFlashMessageWithType(
        applicationConfigContext.applicationConfiguration,
        status_code as string,
      )
      if (errorData) {
        setShowFlashMessage(errorData)
        setTimeout(() => {
          setShowFlashMessage(null)
        }, 5000) // 5 seconds
      }
    }

    if (loginStatus) {
      getUserProfileData()
    }

    if (code) {
      validateCode(code as string)
        .then(async (res: any) => {
          const { LoginData, Profile, Key } = res
          // save token to localstorage
          if (Key) {
            localStorage.setItem("Key", JSON.stringify(Key))
            const getSessionDetails = await postApi("/solarapi/doccheck/access_token", {
              token: Key,
            })
            const csrf_token = getSessionDetails?.session_data?.csrf_token
            const hashedSessionId = getSessionDetails?.session_data?.hashedSessionId

            setUserSession(hashedSessionId, csrf_token)

            const salt = process.env.USER_COOKIE_SALT as string
            const isLocalhost = !!location.hostname.includes("localhost")
            const url = getAssetPrefixUrl(
              `/api/data-provider/?id=user&appid=${applicationId}`,
              isLocalhost,
            )
            const { feDomain } = getApiDomainAndLang()

            const response = await fetch(url, {
              headers: {
                sessionId: AES.encrypt(hashedSessionId, salt).toString(),
                csrfToken: AES.encrypt(csrf_token, salt).toString(),
                domainPath: getApplicationPathFromURL(),
                fedomain: feDomain,
              },
            })
            await response.json()
          }

          const loginMessage = getFlashMessage(
            applicationConfigContext.applicationConfiguration,
            "DocCheck login message",
          )
          setToastMessage(`<p>${loginMessage}</p>`)
          addUserData(LoginData)
          setUserProfile(Profile)
          setUserStateGlobally(true, LOGIN_TYPES.DOC_CHECK)

          if (paywalledContent) {
            setTimeout(() => {
              Router.reload()
            }, 1000) 
          }
        })
        .catch((err) => {
          const logoutMessage = getFlashMessage(
            applicationConfigContext.applicationConfiguration,
            "Logout message",
          )
          setToastMessage(`<p>${logoutMessage}</p>`)
        })
        .finally(() => {
          if (!showToastMessage) {
            setShowToastMessage(true)

            setTimeout(() => {
              setShowToastMessage(false)
            }, 5000) // 5 seconds
          }

          const newurl = `${protocol}//${host}${pathname}`
          window.history.pushState({ path: newurl }, "", newurl)
          sessionStorage.removeItem("source")
        })
    } else if (tid && applicationConfigContext.applicationConfiguration.isSandozIDEnabled) {
      if (loginStatus) {
        return
      }
      setProfileLoading(true)
      const loginMessage = getFlashMessage(
        applicationConfigContext.applicationConfiguration,
        "Login message",
      )
      // for login V2 implementation
      fetchTokenAndSessionID(tid as string)
        .then(async (res: any) => {
          const { csrf_token, hashedSessionId } = res
          setUserSession(hashedSessionId, csrf_token)
          if (csrf_token && hashedSessionId) {
            try {
              // check for existing cookies, setup cookies if not found
              const url = `/api/data-provider/?id=check-cookie&appid=${applicationId}`
              const response = await fetch(url, {
                headers: {
                  domainPath: getApplicationPathFromURL(),
                },
              })
              const data = await response.json()
              if (data.isExpire) {
                console.error("User cookies expired, create new cookies")
                await setupCookies(hashedSessionId, csrf_token, applicationId)
              }
              // end setting up cookies
              console.log("fetch logged in user's profile data")
              const profileResponse: any = await getUserProfileData()
              const userData: any = profileResponse?.response
              const userLoginData = {
                code: 200,
                fail: false,
                response: {
                  session_data: {
                    hashedSessionId,
                    csrf_token,
                    b2c_id: "",
                    first_name: userData?.profile?.records?.[0]?.FirstName ?? "",
                    last_name: userData?.profile?.records?.[0]?.LastName ?? "",
                    terms: userData?.profile?.terms,
                    hcp_confirmation: userData?.profile?.hcp_confirmation,
                    user_data: {
                      current_user: {
                        name: userData?.profile?.records?.[0]?.PersonEmail,
                      },
                    },
                    scout_data: userData?.profile?.records?.[0],
                  },
                },
                error: {},
                loginType: LOGIN_TYPES.SOLAR,
              }
              addUserData(userLoginData)

              setShowFlashMessage({
                error_status: "success",
                error_message: `<p>${loginMessage}</p>`,
              })
              setUserStateGlobally(true, LOGIN_TYPES.SOLAR)
              // save app_id in common state once user is logged in
              if (app_id && applicationConfigContext?.applicationConfiguration?.isGlobalSite) {
                updateRefAppID(app_id as string)
              }
            } catch (error) {
              throw new Error("failed to get user details!")
            }
          }
        })
        .catch((err) => {
          console.error("Error occured: ", err)
          setShowFlashMessage({
            error_status: "error",
            error_message: "Something went wrong! Please try again in sometime",
          })
          removeQueryParam()
          setTimeout(() => {
            setShowFlashMessage(null)
          }, 5000) // 5 seconds
        })
        .finally(() => {
          setProfileLoading(false)

          if (showFlashMessage) {
            setTimeout(() => {
              setShowFlashMessage(null)
            }, 5000) // 5 seconds
          }
        })
    }
  }, [
    applicationConfigContext?.applicationConfiguration?.flash_num_msg,
    applicationConfigContext?.applicationConfiguration?.isSandozIDEnabled,
    router.query,
  ])

  /**
   * Get language details
   */
  useEffect(() => {
    if (
      applicationConfigContext?.applicationConfiguration?.isLanguageEnabled &&
      !isMicrositeEnabled
    ) {
      fetchLanguageDataSource()
    }
  }, [applicationConfigContext?.applicationConfiguration?.isLanguageEnabled, isMicrositeEnabled])

  useEffect(() => {
    setShowFloatingBanner(localStorage.getItem("showBanner") ?? "")
  }, [])

  /**
   * manage user session in multiple tabs
   */
  useMemo(() => {
    if (isActiveSession !== undefined && isActiveSession === false && renewUserSession === false) {
      manageUserSession(renewUserSession)
    } else if (isActiveSession === undefined && renewUserSession === true) {
      manageUserSession(renewUserSession)
    }
  }, [isActiveSession, renewUserSession])

  /**
   * hide bookmark, ratings & favourite for docCheck user
   */
  useEffect(() => {
    if (loginStatus && props?.user?.user?.loginType === LOGIN_TYPES.DOC_CHECK) {
      applicationConfigContext.applicationConfiguration.isBookmarkEnabled = false
      applicationConfigContext.applicationConfiguration.isFavouriteEnabled = false
      applicationConfigContext.applicationConfiguration.isRatingEnabled = false
    }
  }, [loginStatus, applicationConfigContext.applicationConfiguration])

  /**
   * ISI banner (v2)
   * update ISI banner details
   */
  useEffect(() => {
    if (isiBannerDetails) {
      const isiBannerObj = {} as any
      if (isiBanner?.isiBannerData && isiBanner?.isiBannerData?.length > 0) {
        const isISINodePresent = isiBanner?.isiBannerData?.some(
          (banner: any) => banner.nodeTitle === isiBannerDetails?.node_title?.[0].value,
        )
        if (!isISINodePresent) {
          isiBannerObj.nodeTitle = isiBannerDetails?.node_title?.[0].value ?? ""
          isiBannerObj.bannerInteractionFlag = 0
          isiBanner?.isiBannerData?.push(isiBannerObj)
          addISIBanner(isiBanner?.isiBannerData)
        }
      } else {
        isiBannerObj.nodeTitle = isiBannerDetails?.node_title?.[0].value ?? ""
        isiBannerObj.bannerInteractionFlag = 0
        addISIBanner([isiBannerObj])
      }
    }
  }, [isiBannerDetails])

  /**
   * eyebrow menu
   */
  useEffect(() => {
    const floatingTop =
      floatingBanner?.display && showFloatingBanner !== "false" ? NUMBERS.FORTY : NUMBERS.ZERO
    const eyebrowTop = eyebrowMenu?.enabled === NUMBERS.ONE ? NUMBERS.FORTY : NUMBERS.ZERO
    const stickyManyTopValue = floatingTop + eyebrowTop

    if (stickyManyTopValue > NUMBERS.ZERO) {
      setStickyTopValue(stickyManyTopValue)
    }
  }, [floatingBanner?.display, eyebrowMenu?.enabled, stickyTopValue, showFloatingBanner])

  /**
   * HCP modal
   */
  useEffect(() => {
    if (hcpModal && hcpModal?.enabled !== 1 && pageHcpModal && pageHcpModal?.enabled !== 1) {
      setHCPInteracted(true)
    }

    if (!isServer()) {
      const domain = JSON.parse(sessionStorage.getItem("feDomainData") as string)
      const key1 = `${domain}_hcpModal`
      const localHcpModal = sessionStorage.getItem(key1)
      const key2 = `${props?.pageAlias}_hcpModal`
      const pageLevelHcpModal = sessionStorage.getItem(key2)
      const pageLevelHcpModalContent = { ...pageHcpModal, pageAlias }
      if (
        pageLevelHcpModal !== "interacted" &&
        pageHcpModal &&
        pageHcpModal?.enabled === 1 &&
        !isDeepLink
      ) {
        setPopUpContent(pageLevelHcpModalContent)
        setShowHCPPopup(true)
      } else if (
        localHcpModal !== "interacted" &&
        hcpModal &&
        hcpModal?.enabled === 1 &&
        pageHcpModal?.enabled !== 1
      ) {
        setPopUpContent(hcpModal)
        setShowHCPPopup(true)
      }
    }
  }, [hcpModal, hcpModal?.enabled, pageHcpModal, pageHcpModal?.enabled, pageAlias])

  useEffect(() => {
    if (!isServer()) {
      let defaultPageURL = `${host}${pathname}`
      const defaultPageURLLength = defaultPageURL?.length
      const defaultPageURLEndChar = defaultPageURL?.at(defaultPageURLLength - 1)
      defaultPageURL =
        defaultPageURLEndChar === "/"
          ? defaultPageURL?.slice(0, defaultPageURLLength - 1)
          : defaultPageURL
      const domain = JSON.parse(sessionStorage.getItem("feDomainData") as string)
      const key1 = `${domain}_hcpModal`
      const localHcpModal = sessionStorage.getItem(key1)
      const key2 = `${props?.pageAlias}_hcpModal`
      const pageLevelHcpModal = sessionStorage.getItem(key2)
      const globalKey =
        pathname === "/" ? `${domain}_hcpModalOnNo` : `${defaultPageURL}_hcpModalOnNo`
      const globalModal = sessionStorage.getItem(globalKey)
      const pageLevelHcpModalContent = { ...pageHcpModal, pageAlias }
      if (
        pageLevelHcpModal !== "interacted" &&
        pageHcpModal &&
        pageHcpModal?.enabled === 1 &&
        !isDeepLink
      ) {
        setPopUpContent(pageLevelHcpModalContent)
        setShowHCPPopup(true)
      } else if (
        localHcpModal !== "interacted" &&
        hcpModal &&
        hcpModal?.enabled === 1 &&
        pageHcpModal?.enabled !== 1 &&
        globalModal !== "interacted"
      ) {
        setPopUpContent(hcpModal)
        setShowHCPPopup(true)
      }
    }
  }, [hcpModal, pageHcpModal, pageAlias])

  useEffect(() => {
    if (showHCPPopup) {
      document.body.style.overflow = "hidden"
    }
  }, [showHCPPopup])

  const userProfileData = props?.user?.profile?.response?.profile
  const userName = userProfileData?.records?.[0]
    ? `${userProfileData?.records?.[0]?.FirstName}
                    ${userProfileData?.records?.[0]?.LastName}`
    : ""

  const userData = {
    name: userName,
    about: "about user",
    loginStatus,
    profileLoading,
  }

  const loginFormProps: ILoginFormDataProps = loginFormData
  const bannerCTAProps: IArticleTeaserBannerProps = loginFormData?.banner

  const PopupProps: IPopup = {
    showPopup: false,
    showOverlay: false,
    spinnerIcon: getAssetPrefixUrl("/assets/icons/spinner.svg") as string,
    closeIcon: {
      url: getAssetPrefixUrl("/assets/icons/close.svg") as string,
      altText: "close icon",
    },
    handlePopupShow: (popupShow: boolean) => popupShow,
  }

  const loginPopupData: ILoginPopup = {
    showPopup: false,
    popup: PopupProps,
    form: loginFormProps,
    bannerCTA: bannerCTAProps,
    handlePopupShow: (popupShow: boolean) => popupShow,
    handleForgotPasswordShow: (popupShow: boolean) => popupShow,
    loginConfirmation: (event: boolean) => event,
  }

  useEffect(() => {
    const fetchMicrositeData = async () => {
      const { pageMenuLvl, menuStructure } = micrositeConfig
      const { menuTree, l1SwitcherData, l2SwitcherData } = countryAndBrandSwitcherData

      if (pageMenuLvl === "0") {
        const menuData =
          menuTree?.[countryAndBrandSwitcherData.rootNodeId]?.map((item: any) => {
            const newItem = _.clone(item)
            newItem.attributes.parent = null
            return newItem
          }) || []

        setPageMegaMenu(menuData ? await renderMegaMenu(null, menuData, applicationConfigContext?.applicationConfiguration?.siteConfig) : [])
      } else if (pageMenuLvl === "1") {
        const l1Page = l1SwitcherData.find((item: any) => item?.uri?.includes(pathname))
        setL1PageNode(l1Page)

        const menuData = l1Page?.id
          ? menuTree?.[l1Page.id]?.flatMap((item: any) => {
              if (item?.attributes?.parent?.includes(`:${l1Page.id}`)) {
                const newItem = _.cloneDeep(item)
                newItem.attributes.parent = null
                return newItem
              }
              return item
            }) || []
          : []

        setPageMegaMenu(menuData && menuData?.length ? await renderMegaMenu(null, menuData, applicationConfigContext?.applicationConfiguration?.siteConfig) : [])
      } else {
        const l1Parent = l1SwitcherData.find(
          (item: any) =>
            !!menuTree?.[item.id]?.some((child: any) => {
              const childLinkUri = child?.attributes?.link?.uri
                ? filterURL(child?.attributes?.link?.uri)
                : ""
              return childLinkUri === `/${pageAlias}`
            }),
        )
        setL1PageNode(l1Parent)
        
        if (menuStructure === "one_level") {
          const menuData = l1Parent?.id
            ? menuTree?.[l1Parent.id]?.flatMap((item: any) => {
                if (item?.attributes?.parent?.includes(`:${l1Parent.id}`)) {
                  const newItem = _.cloneDeep(item)
                  newItem.attributes.parent = null
                  return newItem
                }
                return item
              }) || []
            : []

          setPageMegaMenu(menuData && menuData?.length ? await renderMegaMenu(null, menuData, applicationConfigContext?.applicationConfiguration?.siteConfig) : [])
        }

        if (menuStructure === "one_level") {
          const menuData = l1Parent?.id
            ? menuTree?.[l1Parent.id]?.flatMap((item: any) => {
                if (item?.attributes?.parent?.includes(`:${l1Parent.id}`)) {
                  const newItem = _.cloneDeep(item)
                  newItem.attributes.parent = null
                  return newItem
                }
                return item
              }) || []
            : []

          setPageMegaMenu(menuData && menuData?.length ? await renderMegaMenu(null, menuData) : [])
        }
        else if (menuStructure === "two_level") {
          const l2ParentItem =
            micrositeConfig?.pageMenuLvl === "2"
              ? l2SwitcherData?.[l1Parent?.id]?.find((item: any) => item?.uri === `/${pageAlias}`)
              : l2SwitcherData?.[l1Parent?.id]?.find(
                  (item: any) =>
                    !!menuTree?.[item.id]?.some((child: any) => {
                      const childLinkUri = child?.attributes?.link?.uri
                        ? filterURL(child?.attributes?.link?.uri)
                        : ""
                      return childLinkUri === `/${pageAlias}`
                    }),
                )
          setL2PageNode(l2ParentItem)
          const menuData = l2ParentItem
            ? menuTree?.[l2ParentItem.id]?.flatMap((item: any) => {
                if (item?.attributes?.parent?.includes(`:${l2ParentItem.id}`)) {
                  const newItem = _.cloneDeep(item)
                  newItem.attributes.parent = null
                  return newItem
                }
                return item
              })
            : []
          setPageMegaMenu(menuData ? await renderMegaMenu(null, menuData, applicationConfigContext?.applicationConfiguration?.siteConfig) : [])
        }
      }
    }
    if (isMicrositeEnabled && countryAndBrandSwitcherData) {
      fetchMicrositeData()
    }
  }, [pageAlias, countryAndBrandSwitcherData])

  const { lang } = getApiDomainAndLang()
  const pageConfigData = getCurrentPageConfig(pageAlias, props)
  const eyebrowMenuData = micrositeConfig?.disabledInhConfig?.eyebrow_menu
    ? null
    : pageConfigData?.eyebrowMenu
  const headerTopsectionData = micrositeConfig?.disabledInhConfig?.header
    ? null
    : pageConfigData?.headerTopSection

  const headerData = micrositeConfig?.disabledInhConfig?.header
    ? null
    : {
        loginPopup: loginPopupData,
        // forgotPasswordPopup: dummyForgotPasswordProps,
        languageSelected: {
          tabindex: 1,
          regionicon: <Icon iconName="GlobalIcon" /> || null,
          country: isMicrositeEnabled ? l1PageNode?.title : headerTopSection?.countryName,
          lang,
          href: "javascript:void(0);",
          stateIcon: <Icon iconName="CollapseDownIcon" />,
          ariaLabel: "Language Switch",
          closeIcon: <Icon iconName="Close2" />,
        },
        languageList: {
          title: headerTopSection?.chooseLocationText,
          divider: {
            className: "hr desk-seperator",
          },
          tabsData: isMicrositeEnabled
            ? null
            : {
                tabHeadItems, // update for header API data (desktop header view)
                tabHeadClassName: "tab-nav",
                tabContents,
                tabContentClassName: "language-switcher-countries",
                tabContentID: "pills-tabContent",
                tabWithDropdown: true,
                options: tabOptions, // update for header API data (dropdown mobile and tablet view)
                ariaLabel: "Select language",
                name: "language-dropdown",
              },
          display: true,
          isMicrositeEnabled,
          micrositeL1List: isMicrositeEnabled
            ? countryAndBrandSwitcherData?.l1SwitcherData
            : undefined,
          selectedItem: isMicrositeEnabled ? l1PageNode?.title : headerTopSection?.countryName,
        },
        topSection: headerTopsectionData,
        megaMenu: isMicrositeEnabled ? pageMegaMenu : headerMegaMenu,
        userData,
        floatingBanner,
        eyebrowMenu: eyebrowMenuData,
        headerConfig: pageConfigData?.headerConfig,
      }
  const footerData = micrositeConfig?.disabledInhConfig?.footer ? null : pageConfigData?.footer

  const searchHandler = (searchText: string) => {
    console.log("Here needs to be added the general search functionality")
    console.log(`You searched for ${searchText}`)
  }

  const onFloatingBannerCloseHandler = () => {
    setShowFloatingBanner("false")
  }

  const onHCPCloseHandler = () => {
    setHCPInteracted(true)
  }

  const getUserProfileData = async () => {
    const profileResponse = await fetchUserProfile()
    if (!profileResponse.fail) {
      setUserProfile(profileResponse)
      return profileResponse
    }
    resetUserAndProfile({})
  }

  const createDocCheckUser = () => {
    const LoginData = {
      sessionId: null,
      first_name: "Doccheck",
      last_name: "User",
      terms: "0",
      hcp_confirmation: "0",
      user_data: {
        current_user: {
          name: "Doccheck User",
        },
        csrf_token: null,
        logout_token: null,
      },
      scout_data: null,
      loginType: LOGIN_TYPES.DOC_CHECK,
    }

    const Profile = {
      bookmark: [],
      favourite: {},
      profile: {
        totalSize: 1,
        done: true,
        records: [
          {
            Salutation: "Mr",
            FirstName: "Docheck",
            LastName: "User",
            Type: "Doctor",
            SCT_Primary_Specialty__c: null,
            SCT_National_ID__c: null,
            SCT_Primary_Parent__r: null,
            BillingState: null,
            SCT_Sharing_Code__c: null,
            SCT_Email_Consent__c: null,
          },
        ],
        terms: "0",
        hcp_confirmation: "0",
      },
    }

    return {
      LoginData,
      Profile,
    }
  }

  const validateCode = async (doccheckCode: string) => {
    try {
      // Doc check API
      const siteConfigData = applicationConfigContext?.applicationConfiguration?.siteConfig
      const loginId = siteConfigData?.doccheck_login_id
      const clientSecret = siteConfigData?.doccheck_clientsecret
      const baseDomain = siteConfigData?.doccheck_base_domain
      const oAuthURL = `https://${baseDomain}/service/oauth/access_token`

      let url = ""
      if (doccheckCode) {
        url = `${oAuthURL}/?client_id=${loginId}&client_secret=${clientSecret}&code=${doccheckCode}&grant_type=authorization_code`
        // Get the access token from the tokenUrl response
        const tokenResponse = await fetch(url, {
          method: "POST",
        })

        if (tokenResponse.status != 200) {
          throw new Error(`Request failed with status: ${tokenResponse.status}`)
        }

        const tokenData = await tokenResponse.json()
        const accessToken = tokenData.access_token
        const { LoginData, Profile } = createDocCheckUser()
        return { LoginData, Profile, Key: accessToken }
      }
    } catch (error) {
      // Handle any errors just print
      console.error(error)
    }
  }

  const brandSwitcherData =
    isMicrositeEnabled && micrositeConfig?.menuStructure === "two_level"
      ? {
          options: countryAndBrandSwitcherData?.l2SwitcherData?.[l1PageNode?.id] || [],
          selectedItem: l2PageNode || null,
          handleSelect: (option: any) => {
            router.push(option.uri)
          },
        }
      : {}

  const header = (
    <>
      {applicationTheme == "rebranding" ||
      applicationTheme == "rebranding-japan" ||
      applicationTheme == "jubbonti" ||
      applicationTheme == "wyost" ||
      applicationTheme === "biosimilarsinbone" ||
      applicationTheme === "vagidonna" ? (
        <RebrandingHeader
          {...headerData}
          topSection={user?.isLoggedIn && headerData ? {...headerData?.topSection, userMenuDetails: loggedInUserMenu} : headerData?.topSection}
          brandSwitcherData={brandSwitcherData}
          micrositeConfig={micrositeConfig}
          handleSearchFunctionality={searchHandler}
          isDisabled={headerTopSection?.sticky_menu_enable}
          onFloatingBannerClose={onFloatingBannerCloseHandler}
        />
      ) : (
        <Header
          {...headerData}
          topSection={user?.isLoggedIn && headerData ? {...headerData?.topSection, userMenuDetails: loggedInUserMenu} : headerData?.topSection}
          handleSearchFunctionality={searchHandler}
          isDisabled={headerTopSection?.sticky_menu_enable}
          onFloatingBannerClose={onFloatingBannerCloseHandler}
        />
      )}
      {headerTopSection?.sticky_menu_enable && (
        <StickyMenu
          handleSearchFunctionality={searchHandler}
          topSection={headerData?.topSection}
          megaMenu={headerLightMenu?.megaMenu}
          topValue={stickyTopValue}
          searchData={headerTopSection.searchDataContent}
        />
      )}
    </>
  )

  const dynamicGoBack = (
    <>
      {refererUrl &&
        refererUrl !== "" &&
        applicationConfigContext?.applicationConfiguration?.siteConfig?.goback_details_enable ===
          1 &&
        (applicationConfigContext?.applicationConfiguration?.siteConfig
          ?.goback_details_button_position === DYNAMIC_GOBACK_CONSTANTS.BOTTOM ||
          applicationConfigContext?.applicationConfiguration?.siteConfig
            ?.goback_details_button_position === DYNAMIC_GOBACK_CONSTANTS.BOTH) && (
          <GoBackButton
            refererUrl={refererUrl}
            goback_details_target_url={
              applicationConfigContext?.applicationConfiguration?.siteConfig
                ?.goback_details_target_url
            }
            goback_details_button_label_text={
              applicationConfigContext?.applicationConfiguration?.siteConfig
                ?.goback_details_button_label_text
            }
            className="bottom-margin"
          />
        )}
    </>
  )

  const liteFooterData = micrositeConfig?.disabledInhConfig?.footer
    ? null
    : pageConfigData?.liteFooter

  const getDefaultBannerData = () => {
    if (micrositeConfig?.disabledInhConfig?.banner) {
      return null
    }

    if (
      !isExcluded &&
      floatingModule &&
      isiVersion === "0" &&
      floatingModule?.bannerType === "isi_banner"
    ) {
      return { ...floatingModule, bannerType: "isi_banner", isiBannerVersion: isiVersion }
    }

    if (isiBannerDetails && isiVersion === "1" && floatingModule?.bannerType === "isi_banner") {
      return { ...floatingModuleISIData, bannerType: "isi_banner", isiBannerVersion: isiVersion }
    }
    return bannerModuleData
  }

  const pageBannerData = pageConfigData?.banner ?? getDefaultBannerData()

  const footer = (
    <>
      {dynamicGoBack}
      {/* ISI Banner 1.0 */}
      {!isExcluded &&
        floatingModule &&
        isiVersion === "0" &&
        // floatingModule.enabled === 1 &&
        floatingModule?.bannerType === "isi_banner" &&
        !isMicrositeEnabled && <FloatingModuleLegacy data={floatingModule} />}

      {/* ISI MicroSite Banner 1.0 */}
      {!isExcluded &&
        pageBannerData &&
        pageBannerData?.bannerType === "isi_banner" &&
        pageBannerData?.isiBannerVersion === "0" &&
        isMicrositeEnabled && <FloatingModuleLegacy data={pageBannerData} />}

      {/* ISI Banner 2.0 */}
      {isiBannerDetails &&
        isiVersion === "1" &&
        floatingModule?.bannerType === "isi_banner" &&
        !isMicrositeEnabled && <FloatingModule data={floatingModuleISIData} />}

      {/* ISI Microsite 2.0 banner*/}
      {pageBannerData?.bannerType === "isi_banner" &&
        pageBannerData?.isiBannerVersion === "1" &&
        isMicrositeEnabled && <FloatingModule data={pageBannerData} />}

      {/* Legal banner */}
      {pageBannerData && pageBannerData?.bannerType === "legal_banner" && (
        <LegalBannerModule data={pageBannerData} />
      )}

      {/* Reference Text Block */}
      {referenceTextData?.referenceTextFlag &&
        (referenceTextData?.referenceTextTitle || referenceTextData?.referenceTextDesc) && (
          <ReferenceText
            title={referenceTextData?.referenceTextTitle}
            description={referenceTextData?.referenceTextDesc}
          />
        )}

      {liteFooterData?.liteFooterFlag
        ? liteFooterData && <FooterLite {...liteFooterData} />
        : footerData && <Footer {...footerData} />}

      {/* Disclaimer banner  */}
      {pageBannerData && pageBannerData?.bannerType === "disclaimer_banner" && (
        <LegalBannerModule data={pageBannerData} />
      )}
    </>
  )

  return (
    <>
      {applicationConfigContext?.applicationConfiguration?.siteConfig &&
        applicationConfigContext?.applicationConfiguration?.siteConfig?.header_exp_style ===
          "simplified" &&
        headerData &&
         !router?.query?.id && !router?.query?.legacy_id && (
          <SimplifiedHeader
            {...headerData}
            micrositeConfig={micrositeConfig}
            brandSwitcherData={brandSwitcherData}
          />
        )}

      {applicationConfigContext?.applicationConfiguration?.siteConfig &&
        applicationConfigContext?.applicationConfiguration?.siteConfig?.header_exp_style !==
          "simplified" &&
        headerData &&
        !router?.query?.id && !router?.query?.legacy_id &&
        header}
      {showHCPPopup &&  !router?.query?.id && !router?.query?.legacy_id && (
        <HcpModalPopUp {...popUpContent} onClose={onHCPCloseHandler} />
      )}
      {props.children}
      {!loading  && !router?.query?.id  && !router?.query?.legacy_id && footer}
      {showToastMessage && <Notification notificationType="success" displayTxt={toastMessage} />}
      {showFlashMessage && (
        <Notification
          notificationType={showFlashMessage?.error_status || "error"}
          displayTxt={showFlashMessage?.error_message || ""}
        />
      )}
      <TabChangeDetector />
    </>
  )
}

const mapDispatch = {
  setUserProfile: (profile: any) => setUserProfile(profile),
  resetUserAndProfile: (user: any) => resetUser(user),
  addUserData: (user: any) => addUser(user),
  setUserStateGlobally: (loggedIn: boolean, loggedInType: string) =>
    setUserStateGlobally(loggedIn, loggedInType),
  setUserSession: (session: string, csrf: string) => setSession(session, csrf),
  addISIBanner: (isiBannerData: any) => addISIBanner(isiBannerData),
  updateRefAppID: (value: string) => setRefApplicationID(value),
  updateReloadContent: (isReload: boolean) => updateReloadContent(isReload),
}

const mapState = (state: any) => ({
  user: state.user,
  isiBanner: state.isiBanner,
  appSession: state.appSession,
  reloadContent: state.common.reloadContent,
})

export default connect(mapState, mapDispatch)(Layout)

import React, { useContext, useEffect, useRef, useState } from "react"
import { MultiSelect } from "react-multi-select-component"
import { useMediaQuery } from "@helpers/hooks"
import { ICustomItemRendererProps, IDropDownProps, IOption, IOptions } from "./_dropDown.interface"
import ApplicationContext from "@utils/application-context/applicationContext"

const MAX_CHAR_LENGTH = 25

const CustomItemRenderer = ({ checked, option, onClick, disabled }: ICustomItemRendererProps) => (
  <div className={`item-renderer ${disabled && "disabled"}`}>
    <label className="container">
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />
      <span className="checkmark" />
    </label>
    <span>{option.label}</span>
  </div>
)

const CustomDropDown = ({
  options,
  isMulti,
  isCleared,
  simpleDropDownLabel,
  placeHolder,
  onValueChange,
  isDisabled,
  intialValue = [],
  clearCatFlag,
  setClearCatFlag,
  ariaLabel,
  ...rest
}: IDropDownProps) => {
  const [selOption, setSelOption] = useState<IOptions>(intialValue)
  const { applicationConfiguration } = useContext(ApplicationContext)
  const selectPlaceholder =
    applicationConfiguration?.siteConfig?.webstore_list_filters_placeholder_text
  const isFirstRender = useRef(true)
  const isMobile = useMediaQuery("(max-width: 991px)")
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else if (!isMobile) {
      setSelOption(intialValue)
    }
    if (isMobile) {
      setSelOption(intialValue)
    }
  }, [intialValue, selOption])

  useEffect(() => {
    if (clearCatFlag) {
      setSelOption(intialValue)
      setClearCatFlag(false)
    }
  }, [clearCatFlag])

  const changeSelected = (value: any) => {
    if (isMulti) {
      setSelOption(value)
      onValueChange?.(value)
    } else {
      const previoslySelected = [...selOption]
      const newSelected =
        previoslySelected.length > 0
          ? value.filter((item: IOption) => item.value !== previoslySelected[0].value)
          : [...value]

      setSelOption(newSelected)
      onValueChange?.(newSelected)
    }
  }

  const dropdownLabel = (selectedOptions: any[]) => {
    let newLabel = ""
    for (let i = 0; i < selectedOptions.length; i++) {
      const newStr = `, ${selectedOptions[i].label}`
      if ((newLabel + newStr).length <= MAX_CHAR_LENGTH) {
        newLabel += newLabel.length ? newStr : selectedOptions[i].label
      } else {
        const selectedOptionsData= selectedOptions.length > 1 ? `(${selectedOptions.length})` : ""
        newLabel += newLabel.length
          ? `, ... (${selectedOptions.length})`
          : `${selectedOptions[i].label.substring(0, MAX_CHAR_LENGTH)} ...${
            selectedOptionsData
            }`
        break
      }
    }
    const labels = selectedOptions.map((u: any) => u.label).join(", ")
    return isMobile ? labels : newLabel
  }
  const customValueRenderer = (selectedOptions: any[], _options: any[]) => {
    let label : any = ""
    switch (selectedOptions.length) {
      case 0:
        label = simpleDropDownLabel ? placeHolder || "" : selectPlaceholder
        break
      case _options.length:
        label = <span className="value">All</span>
        break
      default: {
        label = <span className="value">{dropdownLabel(selectedOptions)}</span>
        break
      }
    }
    return label
  }



  useEffect(() => {
    setSelOption([])
  }, [isCleared])

  return (
    <>
      <span
        id={ariaLabel ?? "input_select"}
        aria-hidden="true"
        className="item-renderer-hidden-label">
        {simpleDropDownLabel ? placeHolder : selectPlaceholder}
      </span>
      <MultiSelect
        options={options}
        value={selOption}
        onChange={changeSelected}
        labelledBy={ariaLabel ?? "input_select"}
        ItemRenderer={CustomItemRenderer}
        hasSelectAll={isMulti}
        disableSearch={true}
        shouldToggleOnHover={false}
        overrideStrings={{ selectAll: applicationConfiguration?.siteConfig?.event_select_all_text, selectSomeItems: applicationConfiguration?.siteConfig?.event_select_option_text }}
        valueRenderer={customValueRenderer}
        disabled={isDisabled}
        closeOnChangedValue={!isMulti}
        {...rest}
      />
    </>
  )
}

export default CustomDropDown
